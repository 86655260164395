export default {
  admin: [{
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/adminDashboard',
        icon: 'cil-drop',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Data',
        to: '/data',
        icon: 'cil-notes',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'User',
        to: '/user',
        icon: 'cil-bank',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Farmer',
        to: '/farmer',
        icon: 'cil-flower'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Admin',
        to: '/admin',
        icon: 'cil-people'
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Master',
        to: '/master',
        icon: 'cil-star'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'My profile',
        to: '/adminProfile',
        icon: 'cil-user'
      },
    ]
  }],
  bank: [{
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/bankDashboard',
        icon: 'cil-drop',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'My profile',
        to: '/bankProfile',
        icon: 'cil-user'
      },
    ]
  }],
  farmer: [{
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/farmerDashboard',
        icon: 'cil-drop',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Data',
        to: '/farmerData',
        icon: 'cil-notes'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Profile',
        to: '/farmerProfile',
        icon: 'cil-user'
      },
    ]
  }]
}


