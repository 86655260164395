<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none">
        <img src="../../public/logo/logobg.png"  width="200px" height="102px" v-if="!minimize" style="padding:1px;"> 
        <img src="../../public/logo/logobg.png"  width="70px" height="46px" v-else style="padding:1px;"> 
    </CSidebarBrand>


    <CRenderFunction flat :content-to-render="nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  computed: {
    nav(){
      var role= localStorage.getItem('userRole');

      return nav[role];

    },

    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>