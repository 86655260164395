<template>
    <div>
        <!-- Alert messages on error -->
        <div>
            <CAlert color="danger" v-show="checkError">
                {{ this.errorMessage }}
            </CAlert>
        </div>
        <div>
            <CDropdown
                inNav
                class="c-header-nav-items"
                placement="bottom-end"
                add-menu-classes="pt-0"
            >
                <template #toggler>
                    <CHeaderNavLink>
                        <div class="c-avatar">
                            <img :src="img" class="c-avatar-img" />
                        </div>
                    </CHeaderNavLink>
                </template>

                <CDropdownItem @click="logout">
                    <!-- <router-link to="/pages/login"> -->
                    <CIcon name="cil-lock-locked" />
                    Logout
                    <!-- </router-link> -->
                </CDropdownItem>
            </CDropdown>
        </div>
    </div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const logoutUrl = URL + `${"profile/logout/"}`;
const profileImageUrl = URL + `${"profile/image/"}`;

export default {
    name: "TheHeaderDropdownAccnt",
    data() {
        return {
            itemsCount: 42,
            img: null,
            errorMessage: "",
            checkError: false,

        };
    },
    methods: {
        getProfileImage() {
            this.axios
                .get(profileImageUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if (response.data.context !== null) {
                        this.img = response.data.context;
                    } else {
                        this.img = "img/avatars/default.png";
                    }
                    console.log(response.data.message);
                })
                .catch((error) => {
                    this.img = "img/avatars/default.png";
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    } catch {
                        console.log(error.message);
                        this.errorMessage = error.message;
                    }
                });
        },
        logout() {
            this.axios
                .get(logoutUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    console.log(response.data.message);
                    localStorage.removeItem("token");
                    this.$router.push("/pages/login");
                })
                .catch((error) => {
                    localStorage.removeItem("token");
                    this.$router.push("/pages/login");
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    } catch {
                        console.log(error.message);
                        this.errorMessage = error.message;
                    }

                });
        },
    },

    beforeMount() {
        this.getProfileImage();
    },
};
</script>

<style scoped>
.c-icon {
    margin-right: 0.3rem;
}
</style>